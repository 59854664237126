import { Button } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { BsFillCaretRightFill } from "react-icons/bs";
import { fileUrl } from "../constants/const";
import Hls from "hls.js";
const VideoPlayer = ({ videoUrl, thumbnailLink, streamType }) => {
  const [showPlayer, setShowPlayer] = useState(false);
  const elementRef = useRef(null);

  const requestFullscreen = () => {
    setShowPlayer(true);
  };

  let videoLink;
  if (videoUrl) {
    if (streamType === "pre") {
      videoLink = fileUrl + videoUrl;
    } else {
      videoLink = `${fileUrl}streams/` + videoUrl;
    }
  }

  useEffect(() => {
    if (showPlayer && elementRef.current) {
      if (elementRef.current.requestFullscreen) {
        elementRef.current.requestFullscreen();
      } else if (elementRef.current.mozRequestFullScreen) {
        elementRef.current.mozRequestFullScreen();
      } else if (elementRef.current.webkitRequestFullscreen) {
        elementRef.current.webkitRequestFullscreen();
      } else if (elementRef.current.msRequestFullscreen) {
        elementRef.current.msRequestFullscreen();
      }
    }
    if (videoLink && videoLink.endsWith(".m3u8") && elementRef.current) {
      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(videoLink);
        hls.attachMedia(elementRef.current);
      } else if (elementRef.current.canPlayType("application/vnd.apple.mpegurl")) {
        elementRef.current.src = videoLink;
      }
    }
  }, [showPlayer, videoLink]);

  return (
    <div className="video-container d-flex justify-content-center flex-column align-items-center">
      {showPlayer ? (
        <div className="mt-3">
          {videoLink && (
            <video controls autoPlay ref={elementRef} style={{ width: "200px", height: "200px" }}>
              {videoLink.endsWith(".mp4") && <source src={videoLink} type="video/mp4" />}
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      ) : (
        <>
          <img src={thumbnailLink} alt="avatar" type="square" style={{ width: "175px", borderRadius: "10px" }} />
          <Button className="mt-2" onClick={() => requestFullscreen()}>View Video</Button>
        </>
      )}
    </div>
  );
};

  export default VideoPlayer;